import React, { lazy } from "react";
const SharedLayout = lazy(() => import("./SharedLayout"));

// Lazy-loaded components
const Login = lazy(() => import("./login"));
const Bien = lazy(() => import("./bien"));
const Payement = lazy(() => import("./payement"));
const Seccuss = lazy(() => import("./secuss-payement"));
const ErrorPayement = lazy(() => import("./error/ErrorPayement"));
const ErrorReponse = lazy(() => import("./error/ErrorReponse"));
const ErrorRedirectionPayement = lazy(() =>
  import("./error/ErrorRedirectionPayement")
);
const ErrorNetwork = lazy(() => import("./error/ErrorNetwork"));
const QuittancesPayees = lazy(() => import("./quittancesPayees"));
const QuittancesNonPayees = lazy(() => import("./quittancesNonPayees"));
const Error404 = lazy(() => import("./error/Error404"));
const MyOperations = lazy(() => import("./myOperations"));
const Doleance = lazy(() => import("./doleance"));
const NewDoleance = lazy(() => import("./doleance/new-doleance"));
const Miseendemeure = lazy(() => import("./miseendemeure"));
const MovementContrat = lazy(() => import("./movement-contrat"));
const Sondage = lazy(() => import("./sondage"));

export const RoutesConfig = (code) => {
  return [
    {
      path: "/",
      element: <SharedLayout />,
      protected: true,
      children: [
        { path: "/" ,index: true, element: <Bien /> },
        { path: "Payement", element: <Payement /> },
        { path: "Seccuss/:orderNumber", element: <Seccuss /> },
        { path: "PayementError/:orderNumber", element: <ErrorPayement /> },
        {
          path: "ErrorRedirectionPayement",
          element: <ErrorRedirectionPayement />,
        },
        { path: "QuittancesPayees", element: <QuittancesPayees /> },
        { path: "QuittancesNonPayees", element: <QuittancesNonPayees /> },
        { path: "operations_client", element: <MyOperations /> },
        { path: "doleance", element: <Doleance /> },
        { path: "nouveau-doleance", element: <NewDoleance /> },
        { path: "miseendemeure", element: <Miseendemeure /> },
        { path: "movement-contrat", element: <MovementContrat /> },
        { path: "sondage", element: <Sondage /> },
      ],
    },
    {
      path: "/Error",
      element: <SharedLayout />,
      children: [
        { path: "/Error", element: <ErrorReponse status={code} /> },
        { path: "/ErrorNetwork", element: <ErrorNetwork /> },
        { path: "*", element: <Error404 /> },
      ],
    },
    { path: "/Login", element: <Login />, protected: false },
  ];
};
