import React, { useState, useEffect, useContext } from "react";
const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [compenentName , setCompenentName] = useState("List");
    const [isAuthentified, setIsAuthentified] = useState(true);
    const [code, setCode] = useState(0);
    const [language, setLanguage] = useState("");
    const [bundle, setBundle] = useState({});
    const [user , setUser] = useState(null);
    const [bienInfoBase , setBienInfoBase] = useState({});
    const [showSlider, setShowSilder] = useState(false);
    const annuler = ()=>{
      setCompenentName("List");
    }
    
    useEffect(() => {
      const lang = localStorage.getItem("myLanguage");
      if (lang) {
        setLanguage(lang);
        import(`./bundle/${lang}.json`)
          .then((bundle) => setBundle(bundle))
          .catch((error) => {
            // Handle error if the bundle fails to load
            console.error("Error loading language bundle:", error);
            // Fallback to default bundle
            setBundle(require("./bundle/fr.json"));
          });
      } else {
        setLanguage("fr");
        import("./bundle/fr.json")
          .then((bundle) => setBundle(bundle))
          .catch((error) => {
            // Handle error if the bundle fails to load
            console.error("Error loading language bundle:", error);
          });
      }
    }, [setLanguage]);

    return <AuthContext.Provider value={
        {
            isAuthentified,
            code,
            language,
            bundle,
            user,
            showSlider,
            compenentName,
            bienInfoBase,
            setIsAuthentified,
            setCode,
            setLanguage,
            setBundle,
            setUser,
            setShowSilder, 
            setCompenentName,
            annuler,
            setBienInfoBase
        }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}

export { AuthProvider }