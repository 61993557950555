import React from 'react';
import ReactDOM from 'react-dom/client';

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider} from "./AuthContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

const lang = localStorage.getItem('myLanguage');


if(!lang){
    localStorage.setItem('myLanguage' , 'ar');
}
root.render(
    <AuthProvider>
        <App />
    </AuthProvider>

);

reportWebVitals();
