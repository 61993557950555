import "./App.css";
import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { RoutesConfig } from "./routesConfig ";

// Components that will be directly imported
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

function App() {
  const { isAuthentified, code } = useAuth();
  const renderRoutes = (routes, isAuthentified) =>
    routes.map((route, index) => {
      if (route.protected) {
        return (
          <Route
            key={index}
            element={<ProtectedRoute isAuthentified={isAuthentified} />}
          >
            <Route path={route.path} element={route.element}>
              {route.children && renderRoutes(route.children, isAuthentified)}
            </Route>
          </Route>
        );
      }
      return (
        <Route key={index} path={route.path} element={route.element}>
          {route.children && renderRoutes(route.children, isAuthentified)}
        </Route>
      );
    });

  return (
    <div>
      <BrowserRouter>
        <Routes>{renderRoutes(RoutesConfig(code), isAuthentified)}</Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
